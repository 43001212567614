exports.components = {
  "component---src-pages-academia-js": () => import("./../../../src/pages/academia.js" /* webpackChunkName: "component---src-pages-academia-js" */),
  "component---src-pages-asd-js": () => import("./../../../src/pages/asd.js" /* webpackChunkName: "component---src-pages-asd-js" */),
  "component---src-pages-community-js": () => import("./../../../src/pages/community.js" /* webpackChunkName: "component---src-pages-community-js" */),
  "component---src-pages-company-js": () => import("./../../../src/pages/company.js" /* webpackChunkName: "component---src-pages-company-js" */),
  "component---src-pages-contact-js": () => import("./../../../src/pages/contact.js" /* webpackChunkName: "component---src-pages-contact-js" */),
  "component---src-pages-download-js": () => import("./../../../src/pages/download.js" /* webpackChunkName: "component---src-pages-download-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-login-js": () => import("./../../../src/pages/Login.js" /* webpackChunkName: "component---src-pages-login-js" */),
  "component---src-pages-news-js": () => import("./../../../src/pages/news.js" /* webpackChunkName: "component---src-pages-news-js" */),
  "component---src-pages-partners-js": () => import("./../../../src/pages/partners.js" /* webpackChunkName: "component---src-pages-partners-js" */),
  "component---src-pages-privacy-js": () => import("./../../../src/pages/Privacy.js" /* webpackChunkName: "component---src-pages-privacy-js" */),
  "component---src-pages-support-js": () => import("./../../../src/pages/support.js" /* webpackChunkName: "component---src-pages-support-js" */),
  "component---src-pages-verumdezyne-js": () => import("./../../../src/pages/verumdezyne.js" /* webpackChunkName: "component---src-pages-verumdezyne-js" */),
  "component---src-templates-news-detail-js": () => import("./../../../src/templates/NewsDetail.js" /* webpackChunkName: "component---src-templates-news-detail-js" */),
  "component---src-templates-news-year-js": () => import("./../../../src/templates/NewsYear.js" /* webpackChunkName: "component---src-templates-news-year-js" */),
  "component---src-templates-partner-detail-js": () => import("./../../../src/templates/PartnerDetail.js" /* webpackChunkName: "component---src-templates-partner-detail-js" */)
}

